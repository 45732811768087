import React, { FC } from 'react';
import { Routes, Route } from 'react-router-dom';

import LoadingPage from '../pages/loading.page';
const HomePage = React.lazy(() => import('../pages/home.page'));
const ResumePage = React.lazy(() => import('../pages/resume.page'));
const NotFoundPage = React.lazy(() => import('../pages/not_found.page'));

const MainRouter: FC = () => {
    return (
        <React.Suspense fallback={<LoadingPage />}>
            <Routes>
                <Route path="/">
                    <Route index element={<HomePage />} />
                    <Route path='resume' element={<ResumePage />} />

                    <Route path=':locale'>
                        <Route index element={<HomePage />} />
                        <Route path='resume' element={<ResumePage />} />
                    </Route>
                </Route>

                <Route path='/not_found' element={<NotFoundPage />} />
                <Route path='*' element={<NotFoundPage />} />
            </Routes>
        </React.Suspense>
    );
}

export default MainRouter;