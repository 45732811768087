import React, { FC } from 'react';
import Translate from '../translate.component';

const LoadingPage: FC = () => {

    return (
        <div className="flex justify-center items-center h-screen px-4 py-2 font-semibold leading-6 text-xl text-slate-600 transition ease-in-out duration-150">
            <div className='content-center'>
                <svg className="animate-spin -ml-1 mr-3 h-12 w-12 text-slate-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
            </div>
            <div className='content-center'>
                <Translate>loading</Translate>...
            </div>
        </div>
    )
}

export default LoadingPage;